import React, { useContext, useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { IconPlus } from '@tabler/icons-react';
import { User } from '@totem/roles';
import { Flex } from 'deprecated-enkel';
import styled from 'styled-components';

import { DISCARD_NEW_TOTEM } from 'data/mutations';
import { GET_TOTEMS } from 'data/queries';

import { ARCHIVED } from 'constants/states-app';
import { TOTEM_ICONS } from 'constants/totemIcons';
import { UserContext } from 'constants/user-context';

import { ModalContext } from 'contexts/ModalContext';

import { AccountMenu } from 'wrappers/AccountMenu';

import { OutsideClickTracker } from 'components/OutsideClickTracker';
import { CreateTotemPopUp } from 'components/CreateTotemPopUp';

import { Tooltip } from 'styles';
import {
    TotemIconContainer,
    TotemsIconsContainer,
    TotemIconItem,
    ActiveMark,
    NewTotemIcon,
    CloseIcon,
} from 'styles/wrappers/navbar.styles';
import { RemoveIcon } from 'styles/components/card.styles';
import { Popup, PopupContent, CloseContainer, PopupTitle } from 'styles/pages/settings.styles';
import { EmojiLabel } from 'components/EmojiLabel';

import { TotemButton } from 'ui/Button';

import { HandleNotification } from 'utils';
import { buildImgUrl } from 'utils/image/buildImgUrl';
import { createOptimizedImageUri } from 'utils/image/createOptimizedImageUri';

import { totemDeletedCacheHandler } from 'utils/cache/totemDeletedCacheHandler';

import { analyticsEvents, track } from 'tracking/segment';
import { useNavigate } from 'react-router-dom';

export const Navbar = () => {
    const apolloClient = useApolloClient();
    const { organization } = useContext(UserContext);
    const { setIsModalOpen, setModal } = useContext(ModalContext);
    const isGlobalAdmin = User.isGlobalAdmin();
    const [popupOpen, setPopupOpen] = useState(false);
    const [, setOverlayOpen] = useState(false);
    const [discardNewTotem] = useMutation(DISCARD_NEW_TOTEM);

    const navigate = useNavigate();

    const { loading, error, data: totemData } = useQuery(GET_TOTEMS);

    if (error) {
        throw error;
    }

    if (loading) {
        return null;
    }

    const { totemsOfUser: totems } = totemData;

    const handleDiscardTotem = async (totemId, number) => {
        try {
            if (totems.length !== number) {
                setPopupOpen(true);
            } else {
                track(analyticsEvents.DISCARD_TOTEM);
                await discardNewTotem({ variables: { totemId } });
                totemDeletedCacheHandler({
                    apolloClient,
                    deletedTotemId: totemId,
                });
                navigate('/');
            }
        } catch (error) {
            HandleNotification(error);
        }
    };

    function isItemActive(uri, homepage) {
        return homepage ? window.location.pathname === uri : window.location.pathname.includes(uri);
    }
    return (
        <NavBarContainer>
            <IconsContainer height="50%">
                <TotemsIconsContainer>
                    {totems.map((totem) => {
                        const { _id, name, number, state, hasValidatedOrders, microstore } = totem;
                        const totemCard = `img/illustrations/Totem_illustration-${
                            TOTEM_ICONS[(number - 1) % 6 ?? 6].id
                        }.png`;
                        const showTotem = User.isAdmin(_id) || User.isEditor(_id);
                        return (
                            showTotem && (
                                <TotemIconContainer key={_id}>
                                    {state === ARCHIVED && (
                                        <EmojiLabel src="🔴" size="14px" position={{ top: 0, right: '6px' }} />
                                    )}
                                    <TotemIconItem
                                        to={`/totem/${_id}`}
                                        color={TOTEM_ICONS[(number - 1) % 6 ?? 6].color}
                                        data-for="totemTooltip"
                                        data-tip={name || `TOTEM #${number}`}
                                        $imgsrc={createOptimizedImageUri({
                                            src: buildImgUrl(totemCard),
                                        })}
                                        $active={isItemActive(`/totem/${_id}`)}
                                        data-test="totem-icon"
                                        onClick={() => track(analyticsEvents.CLICK_TOTEM_ICON)}
                                        $imageSize="65% 65%"
                                    />
                                    <Tooltip id="totemTooltip" type="dark" effect="solid" place="right" />
                                    <ActiveMark $active={isItemActive(`/totem/${_id}`)} />
                                    {!hasValidatedOrders && !microstore && number !== 1 ? (
                                        <>
                                            <CloseIcon
                                                size="15"
                                                onClick={() => handleDiscardTotem(_id, number)}
                                                $active={isItemActive(`/totem/${_id}`)}
                                            />
                                            <NewTotemIcon size="17" $active={isItemActive(`/totem/${_id}`)} />
                                        </>
                                    ) : null}
                                </TotemIconContainer>
                            )
                        );
                    })}
                </TotemsIconsContainer>
                {isGlobalAdmin && (
                    <IconContainer>
                        <IconButtonItem
                            data-for="createTotemTooltip"
                            data-tip="Créer un autre point de livraison"
                            data-test="add-totem"
                            onClick={() => {
                                setIsModalOpen(true);
                                setModal(<CreateTotemPopUp totems={totems} setIsModalOpen={setIsModalOpen} />);
                                track(analyticsEvents.START_TOTEM_CREATION);
                            }}
                            type="secondary"
                        >
                            <IconPlus />
                        </IconButtonItem>
                        <Tooltip id="createTotemTooltip" type="dark" effect="solid" place="right" />
                    </IconContainer>
                )}
            </IconsContainer>
            <IconsContainer height="50%" $pullBottom>
                <OutsideClickTracker
                    setOverlayOpen={setOverlayOpen}
                    render={(open, setOpen, setOverlayOpen) => (
                        <AccountMenu
                            open={open}
                            setOpen={setOpen}
                            setOverlayOpen={setOverlayOpen}
                            imageUrl={organization.imageUrl}
                        />
                    )}
                />
            </IconsContainer>
            {popupOpen && (
                <Popup $alignItems="center" $justifyContent="center" onClick={() => setPopupOpen(false)}>
                    <PopupContent
                        $paddings={[2, 2, 4, 2]}
                        $spaceBetween
                        $width="520px"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <PopupTitle>Suppression de TOTEM</PopupTitle>
                        <Flex $direction="column">
                            <InfoText>
                                Pour supprimer ce TOTEM, vous devez d'abord supprimer le TOTEM n°{totems.length}
                            </InfoText>
                            <TotemButton onClick={() => setPopupOpen(false)}>J'ai compris</TotemButton>
                        </Flex>
                        <CloseContainer>
                            <RemoveIcon size="25" onClick={() => setPopupOpen(false)} />
                        </CloseContainer>
                    </PopupContent>
                </Popup>
            )}
        </NavBarContainer>
    );
};

const InfoText = styled.p`
    margin-bottom: 30px;
`;

const NavBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
`;

const IconsContainer = styled.div`
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: ${(props) => (props.centered ? 'center' : props.$pullBottom ? 'flex-end' : 'flex-start')};
    flex: 1;
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    height: ${({ height }) => (height ? height : 75)}px;
`;

const IconButtonItem = styled.button`
    cursor: pointer;
    position: relative;
    transition: all 0.25s ease;
    color: ${(props) => (props.type === 'secondary' ? props.theme.colors.pantinGrey : props.theme.colors.black)};
    border: 2px solid ${(props) => (props.type === 'secondary' ? 'transparent' : props.theme.colors.greenDarker)};
    background: ${(props) => (props.type === 'secondary' ? 'transparent' : props.theme.colors.green)};
    &:hover {
        background: ${(props) =>
            props.type === 'secondary' ? props.theme.colors.darkGrey : props.theme.colors.greenDarker};
    }
    border-radius: ${({ theme }) => theme.variables.borderRadius};
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
