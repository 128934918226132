import React, { useContext, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Flex } from 'deprecated-enkel';
import { CreditCard } from '@styled-icons/boxicons-regular';
import { Bill } from '@styled-icons/remix-fill';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useForm } from 'react-hook-form';
import { ClipLoader } from 'react-spinners';

import { UserContext } from 'constants/user-context';

import { SET_STRIPE_DEFAULT_PAYMENT_METHOD, UPDATE_ORGANIZATION_BILLING_INFO } from 'data/mutations';

import { PaymentCards } from 'pages/Settings/SettingsPage/SettingsPayment/PaymentCards';
import { getStripeApiKey, setInvoiceInitialValues } from 'pages/Settings/SettingsPage/SettingsPayment/utils';

import { Loading } from 'components/Loading';

import { InlineError } from 'ui/InlineError';
import { TotemButton } from 'ui/Button';

import { handleTextInput, objectChangeCheck, validateEmail } from 'utils';

import { TotemInput, TotemLabel } from 'styles';
import {
    SettingsTitleContainerWrapper,
    SettingsTitleContainer,
    SettingsTitle,
    SettingsPageStyle,
    SettingsCard,
    SettingsForm,
    SettingsLeftHalf,
    SettingsRightHalf,
} from 'styles/pages/settings.styles';

import { analyticsEvents, analyticsPropertiesConstants, analyticsSourceConstants, track } from 'tracking/segment';

export const SettingsPagePayment = () => {
    const { organization, user } = useContext(UserContext);
    const [stripePromise] = useState(() => loadStripe(getStripeApiKey(organization)));
    const [setStripeDefaultPaymentMethod] = useMutation(SET_STRIPE_DEFAULT_PAYMENT_METHOD);
    const [updateOrganizationBillingInfo, { loading: updateOrganizationBillingInfoLoading }] = useMutation(
        UPDATE_ORGANIZATION_BILLING_INFO,
    );
    const [selectedCardIndex, setSelectedCardIndex] = useState();
    const [invoiceValues, setInvoiceValues] = useState(setInvoiceInitialValues(organization));
    const [selectedCardModified, setSelectedCardModified] = useState(false);
    const [popupWindowOpen, setPopupWindowOpen] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: setInvoiceInitialValues(organization),
    });

    const hasCards = !!organization?.cards?.length;
    const isStripeCustomer = Boolean(organization.stripeId);
    const originalInvoiceValues = setInvoiceInitialValues(organization);

    let originalCardIndex = null;
    const isLoading = updateOrganizationBillingInfoLoading;

    if (organization && organization.cards) {
        originalCardIndex = organization.cards.findIndex(
            (card) => card.id === organization.billingInfo?.defaultPaymentMethod,
        );
    }

    useEffect(() => {
        setInvoiceValues(setInvoiceInitialValues(organization));
    }, [organization]);

    useEffect(() => {
        if (hasCards) {
            const selectedCardIndex = organization.cards.findIndex(
                (card) => card.id === organization.billingInfo?.defaultPaymentMethod,
            );

            setSelectedCardIndex(selectedCardIndex);
        }
    }, [organization.billingInfo?.defaultPaymentMethod, organization.cards, hasCards]);

    function handleSetStripeDefaultPaymentMethod(e) {
        e.preventDefault();
        setStripeDefaultPaymentMethod({
            variables: {
                organizationId: organization._id,
                userId: user._id,
                paymentMethodId: organization.cards[selectedCardIndex].id,
            },
        });
        setSelectedCardModified(false);
    }

    async function handleUpdateInvoiceValues() {
        const { invoiceEmail, ...otherInvoiceValues } = invoiceValues;
        const trimmedInvoiceEmail = invoiceEmail.trim();
        const options = {
            variables: {
                organizationId: organization._id,
                organizationBillingInfo: { ...otherInvoiceValues, invoiceEmail: trimmedInvoiceEmail },
            },
        };
        track(analyticsEvents.VALIDATE_BILLING_INFO, {
            [analyticsPropertiesConstants.COMMON.IS_ONBOARDING]: false,
            [analyticsPropertiesConstants.COMMON.SOURCE]: analyticsSourceConstants.SETTINGS,
            [analyticsPropertiesConstants.ORDER.BILLING.HAS_PO_NUMBER]: !!invoiceValues?.invoicePO,
            [analyticsPropertiesConstants.ORDER.BILLING.HAS_TVA_NUMBER]: !!invoiceValues?.invoiceVAT,
        });
        await updateOrganizationBillingInfo(options);
    }

    if (!invoiceValues) {
        return <Loading relative />;
    }

    const invoiceHasChanged = objectChangeCheck(originalInvoiceValues, invoiceValues);

    const controller = (e) => {
        handleTextInput(e, setInvoiceValues, invoiceValues);
    };

    const hasErrors = !!Object.keys(errors).length;

    const hasInvoiceValues = invoiceValues
        ? Object.keys(invoiceValues).every((key) => {
              if (key === 'invoicePO' || key === 'invoiceVAT') {
                  return true;
              }
              return !!invoiceValues[key];
          })
        : false;

    return (
        <SettingsPageStyle>
            <SettingsCard>
                <SettingsForm onSubmit={handleSubmit(handleUpdateInvoiceValues)}>
                    <SettingsTitleContainerWrapper>
                        <SettingsTitleContainer>
                            <Bill />
                            <SettingsTitle>Informations de facturation</SettingsTitle>
                        </SettingsTitleContainer>
                        <TotemButton
                            $alignSelf="flex-end"
                            $fontSize="1em"
                            type="submit"
                            disabled={!(hasInvoiceValues && invoiceHasChanged)}
                            $isLoading={isLoading}
                        >
                            {isLoading ? <ClipLoader color="white" size="16px" /> : 'Mettre à jour'}
                        </TotemButton>
                    </SettingsTitleContainerWrapper>
                    <Flex $flow="row" $wrap="wrap">
                        <SettingsLeftHalf>
                            <TotemLabel>
                                Email de réception des factures* <InlineError display={errors.invoiceEmail} />
                                <TotemInput
                                    placeholder="Entrez l'email de réception des factures"
                                    type="email"
                                    {...register('invoiceEmail', {
                                        required: true,
                                        validate: validateEmail("Votre mail n'est pas valide"),
                                    })}
                                    onChange={controller}
                                    data-test="payment-email"
                                />
                            </TotemLabel>
                            <TotemLabel>
                                Nom d'entreprise pour la facture* <InlineError display={errors.invoiceName} />
                                <TotemInput
                                    placeholder="Entrez le nom d'entreprise pour la facture"
                                    {...register('invoiceName', {
                                        required: true,
                                        minLength: {
                                            value: 3,
                                            message: "Le nom de l'entreprise doit contenir au moins 3 caractères",
                                        },
                                    })}
                                    onChange={controller}
                                    data-test="payment-name"
                                />
                            </TotemLabel>
                            <TotemLabel>
                                Numéro de PO <InlineError display={errors.invoicePO} />
                                <TotemInput
                                    placeholder="Entrez le numéro de PO de l'entreprise"
                                    {...register('invoicePO')}
                                    onChange={controller}
                                    data-test="payment-PO-number"
                                />
                            </TotemLabel>
                            <TotemLabel>
                                Numéro de TVA <InlineError display={errors.invoiceVAT} />
                                <TotemInput
                                    placeholder="Entrez le numéro de TVA de l'entreprise"
                                    {...register('invoiceVAT')}
                                    onChange={controller}
                                    data-test="payment-VAT-number"
                                />
                            </TotemLabel>
                        </SettingsLeftHalf>
                        <SettingsRightHalf>
                            <TotemLabel>
                                Adresse* <InlineError display={errors.invoiceAddress} />
                                <TotemInput
                                    placeholder="Adresse"
                                    {...register('invoiceAddress')}
                                    onChange={controller}
                                    data-test="payment-address"
                                />
                            </TotemLabel>
                            <TotemLabel>
                                Ville* <InlineError display={errors.invoiceCity} />
                                <TotemInput
                                    placeholder="Entrez la ville"
                                    {...register('invoiceCity')}
                                    onChange={controller}
                                    data-test="payment-city"
                                />
                            </TotemLabel>
                            <TotemLabel>
                                Code postal* <InlineError display={errors.invoiceZipcode} />
                                <TotemInput
                                    placeholder="Entrez le code postal"
                                    {...register('invoiceZipcode')}
                                    onChange={controller}
                                    data-test="payment-zipcode"
                                />
                            </TotemLabel>
                            <TotemLabel>
                                Pays* <InlineError display={errors.invoiceCountry} />
                                <TotemInput
                                    placeholder="Entrez le pays"
                                    {...register('invoiceCountry')}
                                    onChange={controller}
                                    data-test="payment-country"
                                />
                            </TotemLabel>
                        </SettingsRightHalf>
                    </Flex>
                </SettingsForm>
            </SettingsCard>
            <SettingsCard>
                <SettingsTitleContainerWrapper>
                    <SettingsTitleContainer>
                        <CreditCard />
                        <SettingsTitle>Mes modes de paiement</SettingsTitle>
                    </SettingsTitleContainer>
                    <Flex>
                        {hasCards && isStripeCustomer && (
                            <TotemButton
                                $margins={[0, 2]}
                                onClick={handleSetStripeDefaultPaymentMethod}
                                $fontSize="1em"
                                disabled={!selectedCardModified}
                            >
                                Changer le mode de paiement par défaut
                            </TotemButton>
                        )}
                        {hasInvoiceValues && !hasErrors && (
                            <TotemButton
                                type="button"
                                $fontSize="1em"
                                onClick={() => {
                                    track(analyticsEvents.START_ADDING_PAYMENT_METHOD, {
                                        [analyticsPropertiesConstants.COMMON.IS_ONBOARDING]: false,
                                    });
                                    setPopupWindowOpen(true);
                                }}
                                data-test="payment-methods-popup-button"
                            >
                                Ajouter un mode de paiement
                            </TotemButton>
                        )}

                        {(!hasInvoiceValues || hasErrors) && !hasCards && (
                            <span>
                                Merci de compléter toutes les informations nécessaires ci-dessus pour ajouter un mode de
                                paiement
                            </span>
                        )}
                    </Flex>
                </SettingsTitleContainerWrapper>
                <Elements locale="fr" stripe={stripePromise}>
                    <PaymentCards
                        organization={organization}
                        user={user}
                        hasCards={hasCards}
                        selectedCardIndex={selectedCardIndex}
                        originalCardIndex={originalCardIndex}
                        setSelectedCardIndex={setSelectedCardIndex}
                        setStripeDefaultPaymentMethod={setStripeDefaultPaymentMethod}
                        setSelectedCardModified={setSelectedCardModified}
                        popupWindowOpen={popupWindowOpen}
                        setPopupWindowOpen={setPopupWindowOpen}
                        invoiceValues={invoiceValues}
                    />
                </Elements>
            </SettingsCard>
        </SettingsPageStyle>
    );
};
