import React, { useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { User } from '@totem/roles';
import { AuthContext } from '@totem/auth';
import { Route, useParams, useLocation, Routes, Navigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import { EQUIPMENTS_URI, ORDER_HISTORY_URI } from 'constants/routes-constants';
import { ARCHIVED } from 'constants/states-app';

import { POST_TO_SLACK, REACTIVATE_TOTEM } from 'data/mutations';
import { GET_CATEGORIES, GET_ORDERS_OF_TOTEM } from 'data/queries';
import { DEFAULT_CATEGORY_ID } from 'constants/categories-constants';

import { TotemCategory } from 'pages/Totem/TotemCategory';
import { TotemDashboard } from 'pages/Totem/TotemDashboard';
import { Equipments } from 'pages/Totem/TotemDashboard/Equipments';
import { OrderHistory } from 'pages/Totem/TotemDashboard/OrderHistory';
import { TotemNav } from 'pages/Totem/TotemNav';
import { TotemNavPlaceholder } from 'pages/Totem/TotemNavPlaceholder';
import { TotemExtendedProduct } from 'pages/Totem/TotemProducts/ExtendedProduct/ExtendedProduct';

import { EmojiLabel } from 'components/EmojiLabel';
import { Loading } from 'components/Loading';
import { TotemButton } from 'ui/Button';

import { ErrorHandler } from 'utils/error-handler';

import {
    TotemStyle,
    TotemInactiveStyle,
    TotemInactiveHeading,
    TotemInactiveSpan,
    TotemLoadingStyle,
} from 'styles/pages/totem.styles';
import { parseQueryStrings } from 'utils';

export const Totem = ({ totems }) => {
    const {
        auth: { loading: authLoading },
    } = useContext(AuthContext);
    const { totemId, navItem, orderId, productId } = useParams();

    const { search } = useLocation();
    const { flow: queryFlow } = parseQueryStrings(search);

    const {
        loading: ordersLoading,
        error: ordersError,
        data: ordersData,
    } = useQuery(GET_ORDERS_OF_TOTEM, {
        variables: { totemId },
        skip: !totemId,
    });
    const {
        loading: categoriesLoading,
        error: categoriesError,
        data: categoriesData,
    } = useQuery(GET_CATEGORIES, {
        variables: { orderId },
    });
    const [reactivateTotem, { loading: reactivateTotemLoading, error: reactivateTotemError }] =
        useMutation(REACTIVATE_TOTEM);
    const [postMessageToSlack] = useMutation(POST_TO_SLACK);

    if (ordersError || categoriesError || reactivateTotemError) {
        throw Error(ordersError || categoriesError || reactivateTotemError);
    }

    const loading = authLoading || categoriesLoading || ordersLoading;

    const isRouteInsideOrderModification = navItem && ![EQUIPMENTS_URI, ORDER_HISTORY_URI].includes(navItem);

    if (loading) {
        return (
            <>
                {isRouteInsideOrderModification && (
                    <TotemStyle>
                        <TotemNavPlaceholder />
                    </TotemStyle>
                )}
                <TotemLoadingStyle>
                    <Loading />
                </TotemLoadingStyle>
            </>
        );
    }

    const { ordersOfTotem } = ordersData;

    const baseUrl = `/totem/${totemId}`;

    if (categoriesError) {
        throw Error(categoriesError);
    }

    const { clientCategories: categories } = categoriesData;

    const { recentOrders } = ordersOfTotem || {};

    const order = (orderId && recentOrders.find((recentOrder) => recentOrder._id === orderId)) || null;
    const lastOrder = order && recentOrders.find((recentOrder) => recentOrder._id === order.previous_orderId);

    const categoriesIds = categories.map((category) => category._id);
    const defaultCategoryId = categoriesIds.includes(DEFAULT_CATEGORY_ID) ? DEFAULT_CATEGORY_ID : categoriesIds[0];
    const currentTotem = totems.find((totem) => totem._id === totemId) || {};
    const isCurrentTotemActive = currentTotem.state !== ARCHIVED;

    if (isCurrentTotemActive && !order && ![EQUIPMENTS_URI, ORDER_HISTORY_URI].includes(navItem)) {
        return (
            <TotemStyle>
                <TotemDashboard
                    ordersOfTotem={ordersOfTotem}
                    currentTotem={currentTotem}
                    baseUrl={baseUrl}
                    defaultCategoryId={defaultCategoryId}
                />
            </TotemStyle>
        );
    }

    const isAnalyst = User.isAnalyst(currentTotem._id);

    if (!totemId) {
        return (
            <Routes>
                <Route render={() => <Navigate to="/" />} />
            </Routes>
        );
    }

    const currentCategory = categories.find((category) => category._id === navItem);
    const categoryFlowSelected = queryFlow ?? currentCategory?.associatedFreefoodProductFlows?.[0];

    if (!currentCategory && isRouteInsideOrderModification) {
        return <Navigate to={`${baseUrl}/${defaultCategoryId}/${orderId}`} replace />;
    }

    if (!navItem && isAnalyst) {
        return <Navigate to={`${baseUrl}/${defaultCategoryId}/${orderId}`} />;
    }

    const handleRestoreTotem = async () => {
        const {
            data: {
                reactivateTotem: { totem: updatedTotem },
            },
        } = await reactivateTotem({ variables: { totemId: currentTotem._id } });

        const message = `🏆 vient de réactiver ('Archived -> Rollout'}) son TOTEM #${updatedTotem.number}.
        Il faut qu’il valide sa commande pour que ce soit pris en compte.`;
        postMessageToSlack({
            variables: {
                message,
            },
        });
    };

    // TODO: FIND A WAY TO MAKE THIS WORK
    // unstable_usePrompt({
    //     message: "N'oubliez pas de valider votre commande pour réserver vos produits et assurer votre date de livraison !",
    //     when: ({ currentLocation, nextLocation }) =>
    //     order.state === INPROGRESS &&
    //     !categoriesIds.some((categoryId) => currentLocation.pathname.includes(categoryId)) &&
    //     isRouteInsideOrderModification &&
    //       currentLocation.pathname !== nextLocation.pathname,
    //   });

    return (
        <ErrorHandler type="totem">
            {isCurrentTotemActive ? (
                <TotemStyle>
                    {!navItem ? (
                        <TotemDashboard
                            ordersOfTotem={ordersOfTotem}
                            currentTotem={currentTotem}
                            baseUrl={baseUrl}
                            defaultCategoryId={defaultCategoryId}
                        />
                    ) : (
                        <>
                            {isRouteInsideOrderModification ? (
                                <>
                                    <TotemNav
                                        categories={categories}
                                        categoryFlowSelected={categoryFlowSelected}
                                        totemId={totemId}
                                        navItem={navItem}
                                        order={order}
                                    />
                                    {productId ? (
                                        <TotemExtendedProduct
                                            currentCategory={currentCategory}
                                            lastOrder={lastOrder}
                                            order={order}
                                        />
                                    ) : (
                                        <TotemCategory
                                            totemId={totemId}
                                            navItem={navItem}
                                            categoryFlowSelected={categoryFlowSelected}
                                            currentCategory={currentCategory}
                                            order={order}
                                            ordersOfTotem={ordersOfTotem}
                                            lastOrder={lastOrder}
                                            totems={totems}
                                        />
                                    )}
                                </>
                            ) : navItem === EQUIPMENTS_URI ? (
                                <Equipments currentTotem={currentTotem} />
                            ) : (
                                navItem === ORDER_HISTORY_URI && (
                                    <OrderHistory
                                        baseUrl={baseUrl}
                                        currentTotem={currentTotem}
                                        defaultCategoryId={defaultCategoryId}
                                        ordersOfTotem={ordersOfTotem}
                                    />
                                )
                            )}
                        </>
                    )}
                </TotemStyle>
            ) : (
                <TotemInactiveStyle>
                    <TotemInactiveHeading>
                        <EmojiLabel src="🔴" size="40px" /> <h2>TOTEM inactif</h2>
                    </TotemInactiveHeading>
                    <TotemInactiveSpan>
                        Vous pouvez réactiver à tout moment votre TOTEM
                        {totems.length > 1 ? ` #${currentTotem.number}` : ''} pour la semaine prochaine.
                    </TotemInactiveSpan>
                    <TotemInactiveSpan>Cliquez sur le bouton ci-dessous.</TotemInactiveSpan>
                    <TotemButton onClick={handleRestoreTotem}>
                        {reactivateTotemLoading ? <ClipLoader color="white" size="16px" /> : 'Réactiver mon TOTEM'}
                    </TotemButton>
                </TotemInactiveStyle>
            )}
        </ErrorHandler>
    );
};
