import React from 'react';
import { Tag } from 'ui/Tag';

import { CardTagsStyle } from 'styles/components/card.styles';

export const ProductTags = ({ product, cursorProp = 'default' }) => {
    const { tags, isBeingTested, isToBeArchived } = product;

    return (
        <CardTagsStyle $cursorProp={cursorProp}>
            <>
                {isBeingTested && <Tag invertColor>Testing</Tag>}
                {tags && tags.length > 0 && tags.map(({ _id, name }) => name && <Tag key={_id}>{name}</Tag>)}
                {isToBeArchived && <Tag invertColor>Bientôt plus disponible</Tag>}
            </>
        </CardTagsStyle>
    );
};
