import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Route, Navigate, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { UserContext } from 'constants/user-context';
import { GET_TOTEMS } from 'data/queries';
import { User } from '@totem/roles';

import { Spying } from 'pages/Spying';
import { Totem } from 'pages/Totem/Totem';
import { Settings } from 'pages/Settings/Settings';
import { UseDesktopWebApp } from 'pages/UseDesktopWebApp';

import { ErrorHandler } from 'utils/error-handler';

import { FourOhFour } from 'components/404';
import { Loading } from 'components/Loading';

import { Overlay } from 'ui/Overlay';

import { isClient, getUrlParameters } from 'utils';
import { isMobile } from 'utils/isMobile';

import { CenterPanelStyle } from 'styles/wrappers/center-panel.styles';
import { TotemLoadingStyle } from 'styles/pages/totem.styles';

export const CenterPanel = () => {
    const { loading, error, data: totemData } = useQuery(GET_TOTEMS);
    const { user, organization } = useContext(UserContext);
    const { pathname } = useLocation();

    if (error) {
        throw error;
    }

    if (loading) {
        return (
            <TotemLoadingStyle>
                <Loading />
            </TotemLoadingStyle>
        );
    }

    const { totemId: totemIdToCheck } = getUrlParameters(pathname);
    const totems = totemData.totemsOfUser.filter(({ _id }) => User.isAdmin(_id) || User.isEditor(_id));
    const totemToUse = totems.find(({ _id }) => _id === totemIdToCheck) || totems[0] || {};
    const { _id: totemId } = totemToUse;

    const isToteam = !isClient();

    if (!user || !organization) {
        return null;
    }

    if (isMobile()) {
        return <UseDesktopWebApp />;
    }

    return (
        <DashboardContainer>
            <CenterPanelStyle>
                <ErrorHandler type="inner">
                    <Overlay name="popup-overlay" />
                    <Routes>
                        {/* default route is TOTEM n°1 */}
                        <Route exact path="/" element={<Navigate to={`/totem/${totemId}`} />} />
                        <Route exact path="/totem" element={<Navigate to={`/totem/${totemId}`} />} />
                        {isToteam && <Route path="/spying" element={<Spying />} />}
                        <Route
                            path="/totem/:totemId?/:navItem?/:orderId?/:productId?"
                            element={<Totem totems={totems} />}
                        />
                        <Route path="/settings/*" element={<Settings user={user} totems={totems} />} />
                        <Route element={<FourOhFour />} />
                    </Routes>
                </ErrorHandler>
            </CenterPanelStyle>
        </DashboardContainer>
    );
};

const DashboardContainer = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;
