import gql from 'graphql-tag';

export const GET_CATEGORIES = gql`
    query GET_CATEGORIES($orderId: String) {
        clientCategories(orderId: $orderId) {
            _id
            associatedFreefoodProductFlows
            title
            isPunctual
            isFreefood
            imageUrl
            state
        }
    }
`;
