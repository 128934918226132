import React, { useState, useRef, useEffect } from 'react';

import { isParisRegion } from '@totem/constants'; // TODO: comment added to rebuild the docker image, remove this later
import { useForm } from 'react-hook-form';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';
import TextareaAutosize from 'react-autosize-textarea';

import { InlineError } from 'ui/InlineError';
import { TotemLabel, TotemInput } from 'styles';
import {
    SettingsAutocompleteContainer,
    SettingsAutocompleteItem,
    SettingsAutocompleteItems,
} from 'styles/pages/settings.styles';

import { TotemButton } from 'ui/Button';

import { throwMessage } from 'utils/LoggingService';
import { formatAddressToShortString, getAutocompleteSuggestions, addressFields } from 'utils/googleMapsAddress';
import { extractKeysFromObject, objectChangeCheck } from 'utils';
import { isEveryRequiredFieldFilled } from 'utils/forms';

import { analyticsEvents, analyticsPropertiesConstants, analyticsSourceConstants, track } from 'tracking/segment';

export const DeliveryInfoForm = ({
    buttonFontSize,
    currentTotem,
    deliveryInfo,
    hasDeliveryInstructions = true,
    hasLocationRestriction = true,
    isAddressNonEditable,
    isCreatingAddress,
    loading,
    onFormSubmitted = () => undefined,
    requestDirty = false,
    setDeliveryInfo,
    sendFormContent,
    submitLabel = 'Valider',
    source = analyticsSourceConstants.DASHBOARD,
}) => {
    const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);
    const [autocompleteOpen, setAutocompleteOpen] = useState(false);
    const [isButtonEnabled, setButtonEnabled] = useState(true);
    const {
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        reValidateMode: 'onSubmit',
    });

    const inputRef = useRef(null);

    const formFields = [...addressFields, 'address_details', 'consigneDelivery'];

    useEffect(() => {
        const requiredFields = hasDeliveryInstructions ? ['address', 'address_details'] : ['address'];
        const fieldsFilled = isEveryRequiredFieldFilled(deliveryInfo, ...requiredFields);
        if (
            fieldsFilled /* the required fields have been filled */ &&
            deliveryInfo.latitude /* the address is well defined with GPS coordinates */ &&
            Object.keys(errors).length === 0 /* form has 0 errors */
        ) {
            if (!isButtonEnabled) {
                setButtonEnabled(true);
            }
        } else if (isButtonEnabled) {
            setButtonEnabled(false);
        }
    }, [deliveryInfo, isButtonEnabled, errors, errors.address, hasDeliveryInstructions]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const fieldsHaveChanged = objectChangeCheck(currentTotem, deliveryInfo, formFields);
        if (fieldsHaveChanged) {
            // check address is in Paris
            clearErrors('address');
            if (!isCreatingAddress) {
                track(analyticsEvents.MODIFY_DELIVERY_INFO, {
                    [analyticsPropertiesConstants.COMMON.SOURCE]: source,
                    [analyticsPropertiesConstants.ORDER.DELIVERY.CITY]: deliveryInfo?.city,
                    [analyticsPropertiesConstants.ORDER.DELIVERY.POSTAL_CODE]: deliveryInfo?.zipcode,
                    [analyticsPropertiesConstants.ORDER.DELIVERY.IS_OUTSIDE_DELIVERY_ZONE]: !isParisRegion(
                        deliveryInfo.zipcode,
                    ),
                });
            }
            if (hasLocationRestriction && !isParisRegion(deliveryInfo.zipcode)) {
                const message = "Cette adresse n'est pas encore dans notre périmètre de livraison.";
                setError('address', { type: 'typeError', message });
                return;
            }
            const newDeliveryInfo = extractKeysFromObject(deliveryInfo, formFields);
            await sendFormContent(newDeliveryInfo);
        }
        onFormSubmitted();
    };

    const handleAddressDetailsChange = ({ target: { name, value } }) => {
        setDeliveryInfo({ ...deliveryInfo, [name]: value });
    };

    const handleAddressSelection = (selectedSuggestion) => () => {
        const placesService = new google.maps.places.PlacesService(document.createElement('div'));
        setAutocompleteOpen(false);

        placesService.getDetails(
            {
                reference: selectedSuggestion.reference,
            },
            (detail, status) => {
                if (status !== 'OK') {
                    throwMessage('error', status);
                } else if (detail?.address_components[0]?.types[0] === 'street_number') {
                    const cityComponent = detail.address_components.find((address_component) =>
                        address_component.types.includes('locality'),
                    );
                    const countryComponent = detail.address_components.find((address_component) =>
                        address_component.types.includes('country'),
                    );
                    const zipCodeComponent = detail.address_components.find((address_component) =>
                        address_component.types.includes('postal_code'),
                    );

                    if (!cityComponent || !countryComponent || !zipCodeComponent) {
                        throwMessage('error', 'An error occurred while saving the address');
                    }

                    const selectionAddressDetails = {
                        address: detail.name,
                        latitude: detail.geometry.location.lat(),
                        longitude: detail.geometry.location.lng(),
                        city: cityComponent.long_name,
                        zipcode: zipCodeComponent.long_name,
                        country: countryComponent.long_name,
                    };

                    inputRef?.current && (inputRef.current.value = formatAddressToShortString(selectionAddressDetails));

                    setDeliveryInfo({ ...deliveryInfo, ...selectionAddressDetails });
                } else {
                    const message = 'Veuillez choisir une adresse complète';
                    setError('address', { type: 'locationError', message });
                }
            },
        );
    };

    const handleAddressAutocompleteChange = ({ target: { value } }) => {
        if (value) {
            getAutocompleteSuggestions(value, setAutocompleteSuggestions);
            clearErrors('address');
            setAutocompleteOpen(true);
        } else {
            setAutocompleteOpen(false);
        }
    };

    const handleBlur = () => {
        setAutocompleteOpen(false);
    };

    const handleFocus = ({ target: { value } }) => {
        if (value) {
            setAutocompleteOpen(true);
        }
    };
    const valueProp = isAddressNonEditable
        ? {
              value: formatAddressToShortString(deliveryInfo),
          }
        : {
              defaultValue:
                  deliveryInfo.address && deliveryInfo.zipcode && deliveryInfo.city
                      ? formatAddressToShortString(deliveryInfo)
                      : '',
          };
    return (
        <FormContainer onSubmit={handleSubmit}>
            <TotemLabel>
                Adresse
                <TotemInput
                    {...valueProp}
                    readOnly={isAddressNonEditable}
                    ref={inputRef}
                    required
                    placeholder="Entrez votre adresse de livraison"
                    name="address"
                    onChange={handleAddressAutocompleteChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    $={[0.25, 0, 1, 0]}
                    data-test="deliveries-address"
                />
                {errors['address'] && <InlineError display={errors.address} />}
            </TotemLabel>
            {autocompleteOpen && autocompleteSuggestions.length > 0 && (
                <SettingsAutocompleteContainer>
                    <SettingsAutocompleteItems>
                        {autocompleteSuggestions.map((suggestion, index) => (
                            <SettingsAutocompleteItem key={index} onMouseDown={handleAddressSelection(suggestion)}>
                                {suggestion.label}
                            </SettingsAutocompleteItem>
                        ))}
                    </SettingsAutocompleteItems>
                </SettingsAutocompleteContainer>
            )}
            {hasDeliveryInstructions && (
                <>
                    <TotemLabel>
                        Étage, code, bâtiment et autres instructions d'accès à votre TOTEM
                        <TotemInput
                            async // necessary because we use styled-component
                            as={TextareaAutosize}
                            required
                            rows={3}
                            placeholder="Entrez les instructions pour permettre au livreur d'accéder à vos locaux"
                            name="address_details"
                            onChange={handleAddressDetailsChange}
                            value={deliveryInfo.address_details}
                            data-test="deliveries-details"
                        />
                    </TotemLabel>
                    <TotemLabel>
                        Consignes de mise en place
                        <TotemInput
                            async // necessary because we use styled-component
                            as={TextareaAutosize}
                            rows={3}
                            placeholder="Entrez vos consignes de mise en place"
                            name="consigneDelivery"
                            onChange={handleAddressDetailsChange}
                            value={deliveryInfo.consigneDelivery}
                            data-test="deliveries-instructions"
                        />
                    </TotemLabel>
                </>
            )}
            <TotemButton
                $margins={[1, 0, 2, 0]}
                data-test="address-modify-button"
                disabled={
                    !isButtonEnabled || (requestDirty && !objectChangeCheck(currentTotem, deliveryInfo, formFields))
                }
                $fontSize={buttonFontSize}
                type="submit"
            >
                {loading ? <ClipLoader color="white" size="16px" /> : submitLabel}
            </TotemButton>
        </FormContainer>
    );
};

const FormContainer = styled.form`
    display: flex;
    width: 100%;
    flex-direction: column;
`;
