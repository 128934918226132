import styled, { css } from 'styled-components';
import { margin, padding, darken, transparentize } from 'polished';
import { animated } from 'react-spring';
import { Link } from 'react-router-dom';

import { FiberNew } from 'styled-icons/material';
import { X } from 'styled-icons/boxicons-regular';

export const NavbarStyle = styled(animated.div)`
    z-index: 3;
    background: ${(props) => props.theme.colors.black};
    position: relative;
    display: flex;
    flex-flow: column;
    flex-shrink: 0;
    width: ${(props) => props.theme.variables.globalNavbarWidth}px;
    height: 100%;
    margin: 0;

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;

export const TotemIconContainer = styled.div`
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    height: 75px;
`;

export const TotemsIconsContainer = styled.div`
    overflow: auto;
    &:first-child {
        margin-top: 2em;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const TotemIconItem = styled(Link)`
    width: 100%;
    height: 75px;
    ${padding('40%', 0)};
    background: url('${(props) => props.$imgsrc}') ${(props) => (props.$transparent ? 'transparent' : props.color)}
        no-repeat center / ${(props) => (props.$fullSize ? 'cover' : props.$imageSize || '95% 95%')};
    object-fit: cover;
    overflow: hidden;
    transition: all 0.25s ease;
    opacity: ${(props) => (props.$active ? 1 : 0.6)};
    cursor: pointer;

    &:last-of-type {
        margin: 0;
    }

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            cursor: default;
            box-shadow: 0;
            pointer-events: none;
        `}
`;

export const ActiveMark = styled.span`
    position: absolute;
    top: 0px;
    left: 0;
    width: 5px;
    height: 75px;
    background-color: ${(props) => props.theme.colors.greenDarker};
    opacity: ${(props) => (props.$active ? 1 : 0)};
    transition: opacity 0.4s ease;
`;

export const CloseIcon = styled(X)`
    position: absolute;
    opacity: ${(props) => (props.$active ? 1 : 0.8)};
    right: 6px;
    top: 3px;
    color: white;
    background: ${(props) => darken(0.05, props.theme.colors.red)};
    border-radius: 100%;
    transition: all 0.25s ease;

    &:hover {
        background: ${(props) => props.theme.colors.red};
        cursor: pointer;
    }
`;

export const NewTotemIcon = styled(FiberNew)`
    position: absolute;
    opacity: ${(props) => (props.$active ? 1 : 0.8)};
    right: 6px;
    top: 18px;
    color: ${(props) => props.theme.colors.white};
`;

export const DropdownContainer = styled.div`
    position: fixed;
    left: ${(props) => props.theme.variables.globalNavbarWidth + props.theme.variables.padding}px;
    bottom: 17px; // used for pixel-perfect aligning the dropdown with the button calling it
    width: auto;
    min-width: ${(props) => props.theme.variables.specificNavbarWidth + 6 * props.theme.variables.padding}px;
    height: max-content;
    overflow-x: auto;
    overflow-y: auto;
    font-size: ${(props) => props.$fontSize};
    color: ${(props) => props.theme.colors.black};
    background: ${(props) => props.theme.colors.white};
    border-radius: ${(props) => props.theme.variables.borderRadius};
    box-shadow: 0 1px 7px 1px ${(props) => transparentize(0.85, props.theme.colors.black)};
    z-index: 10;

    > ul {
        display: flex;
        flex-flow: column;
        justify-content: center;
        margin: 0;
        padding: 0;

        a {
            text-decoration: none;

            &:link,
            &:visited,
            &:active,
            &:focus {
                color: ${(props) => props.theme.colors.black};
            }
        }

        li {
            transition: all 0.25s ease;
            display: flex;
            font-size: 1.25em;
            text-transform: ${(props) => (props.$lowercase ? 'none' : 'uppercase')};
            list-style: none;
            ${({
                theme: {
                    variables: { padding: pad },
                },
                $paddingMultiplier = [],
            }) =>
                padding(
                    pad * ($paddingMultiplier[0] || 0),
                    pad * ($paddingMultiplier[1] || 0),
                    pad * ($paddingMultiplier[2] || 0),
                    pad * ($paddingMultiplier[3] || 0),
                )};

            &:hover {
                color: ${(props) => props.theme.colors.black};
                cursor: pointer;
                background-color: ${(props) => props.theme.colors.green};
            }

            > img {
                width: 16px;
                ${(props) => margin(0, props.theme.variables.margin, 0, 0)};
            }

            > svg {
                ${(props) => margin(0, props.theme.variables.margin * 0.25, 0, 0)};
            }
        }
    }
`;
